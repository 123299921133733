import React from "react";
import "./hero.css";
import images from "../constants/images";

const Hero = ({ t }) => {
  return (
    <>
      <div className="app__hero" id="about">
        <div className="app__hero-overlay"></div>
        <div className="app__hero-content">
          <h1 className="app__hero-heading">{t("about_company")}</h1>
          <p className="app__hero-description">
            {t("hero_description_1")}
            {t("hero_description_2")}
          </p>
        </div>
      </div>
    </>
  );
};

export default Hero;
