import React from "react";
import Footer from "./components/Footer";
import "./components/terms.css";
import TermsNavbar from "./components/TermsNavbar";

const Terms = ({ t }) => {
  return (
    <>
      <TermsNavbar />
      <div className="app__terms">
        <h1 className="app__terms-heading">{t("terms_heading")}</h1>
        <div className="app__terms-content">
          <h3 className="app__terms-content-heading">
            I. {t("terms_subheading_1")}
          </h3>
          <p className="app__terms-content_info">1. {t("terms_info_1_1")}</p>
          <p className="app__terms-content_info">2. {t("terms_info_1_2")}</p>
          <p className="app__terms-content_info">3. {t("terms_info_1_3")}.</p>
          <p className="app__terms-content_info">4. {t("terms_info_1_4")}</p>
          <p className="app__terms-content_subinfo">
            a. {t("terms_subinfo_1_1")}
          </p>
          <p className="app__terms-content_subinfo">
            b. {t("terms_subinfo_1_2")}
          </p>
          <p className="app__terms-content_subinfo">
            c. {t("terms_subinfo_1_3")}
          </p>
          <p className="app__terms-content_subinfo">
            d. {t("terms_subinfo_1_4")}
          </p>
          <h3 className="app__terms-content-heading">
            II. {t("terms_subheading_2")}
          </h3>
          <p className="app__terms-content_info">1. {t("terms_info_2_1")}</p>
          <p className="app__terms-content_info">2. {t("terms_info_2_2")}</p>
          <p className="app__terms-content_subinfo">
            a. {t("terms_subinfo_2_1")}
          </p>
          <p className="app__terms-content_subinfo">
            b. {t("terms_subinfo_2_2")}
          </p>
          <h3 className="app__terms-content-heading">
            III. {t("terms_subheading_3")}
          </h3>
          <p className="app__terms-content_info">1. {t("terms_info_3_1")}</p>
          <h3 className="app__terms-content-heading">
            IV. {t("terms_subheading_4")}
          </h3>
          <p className="app__terms-content_info">1. {t("terms_info_4_1")}</p>
          <p className="app__terms-content_subinfo">
            a. {t("terms_subinfo_4_1")}
          </p>
          <p className="app__terms-content_subinfo">
            b. {t("terms_subinfo_4_2")}
          </p>
          <p className="app__terms-content_subinfo">
            c. {t("terms_subinfo_4_3")}
          </p>
          <p className="app__terms-content_subinfo">
            d. {t("terms_subinfo_4_4")}
          </p>
          <p className="app__terms-content_subinfo">
            e. {t("terms_subinfo_4_5")}
          </p>
          <p className="app__terms-content_subinfo">
            f. {t("terms_subinfo_4_6")}
          </p>
          <p className="app__terms-content-info">2. {t("terms_info_4_2")}</p>
          <h3 className="app__terms-content-heading">
            V. {t("terms_subheading_5")}
          </h3>
          <p className="app__terms-content_info">1. {t("terms_info_5_1")}</p>
          <p className="app__terms-content_subinfo">
            a. {t("terms_subinfo_5_1")}
          </p>
          <p className="app__terms-content_subinfo">
            b. {t("terms_subinfo_5_2")}
          </p>
          <p className="app__terms-content_subinfo">
            c. {t("terms_subinfo_5_3")}
          </p>
          <p className="app__terms-content_subinfo">
            d. {t("terms_subinfo_5_4")}
          </p>
          <p className="app__terms-content_subinfo">
            e. {t("terms_subinfo_5_5")}
          </p>
          <p className="app__terms-content_subinfo">
            f. {t("terms_subinfo_5_6")}
          </p>
          <p className="app__terms-content_subinfo">
            g. {t("terms_subinfo_5_7")}
          </p>
          <p className="app__terms-content_info">2. {t("terms_info_5_2")}</p>
          <h3 className="app__terms-content-heading">
            VI. {t("terms_subheading_6")}
          </h3>
          <p className="app__terms-content_info">1. {t("terms_info_6_1")}</p>
          <p className="app__terms-content_info">2. {t("terms_info_6_2")}</p>
        </div>
      </div>
      <Footer t={t} />
    </>
  );
};

export default Terms;
