import electrotech from "../assets/electrotech.jpg";
import german from "../assets/german.png";
import hero from "../assets/hero.jpg";
import poland from "../assets/poland.png";
import silo from "../assets/silo.jpg";
import uk from "../assets/uk.png";
import weld from "../assets/weld.jpg";
import logoLight from "../assets/dormontLightText.png";
import logoDark from "../assets/doromontDarkText.png";
import logoDarkNoBg from "../assets/doromontNoBgDark.png";
import logoLightNoBg from "../assets/doromontNoBgLight.png";

export default {
  electrotech,
  german,
  hero,
  poland,
  silo,
  uk,
  weld,
  logoLight,
  logoDark,
  logoDarkNoBg,
  logoLightNoBg,
};
