import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = ({ t }) => {
  return (
    <div className="app__footer">
      <p className="app__footer-text">
        Copyright © {new Date().getFullYear()} Doromont |{" "}
        <Link className="app__footer-link" to="/terms-conditions">
          {t("terms_heading")}
        </Link>
      </p>
    </div>
  );
};

export default Footer;
