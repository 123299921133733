import React from "react";
import "./detailsCard.css";
import { RiToolsFill } from "react-icons/ri";

const DetailsCard = ({ image, heading, paragraph, handleClose }) => {
  return (
    <div className="app__details">
      <div className="app__details-content">
        <h2 className="app__details-content_heading">{heading}</h2>
        <p className="app__details-content_info">{paragraph}</p>
      </div>
      <div className="app__details-image_container">
        <img src={image} alt="" className="app__details-image" />
      </div>
      <RiToolsFill className="app__details-icon" onClick={handleClose} />
    </div>
  );
};

export default DetailsCard;
