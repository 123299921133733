import React from "react";
import Contact from "./components/Contact";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
import Footer from "./components/Footer";

const Home = ({ changeLanguage, t }) => {
  return (
    <div>
      <Navbar changeLanguage={changeLanguage} t={t} />
      <Hero t={t} />
      <Services t={t} />
      <Contact t={t} />
      <Footer t={t} />
    </div>
  );
};

export default Home;
