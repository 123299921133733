import React, { useState } from "react";
import "./services.css";
import images from "../constants/images";
import DetailsCard from "./DetailsCard";

const Services = ({ t }) => {
  const [silo, setSilo] = useState(false);
  const [weld, setWeld] = useState(false);
  const [electro, setElectro] = useState(false);

  const handleCloseSilo = () => {
    setSilo(false);
  };

  const handleCloseWeld = () => {
    setWeld(false);
  };

  const handleCloseElectro = () => {
    setElectro(false);
  };

  return (
    <div className="app__services" id="services">
      <h1 className="app__services-heading">{t("offer")}</h1>
      <div className="app__services-container">
        <div className="app__services-card">
          <img
            src={images.silo}
            alt="silo"
            className="app__services-card_image"
          />
          <div className="app__services-card_info">
            <h2 className="app__services-card_heading">
              {t("assembly_heading")}
            </h2>
            <p
              className="app__services-card_link"
              onClick={() => setSilo(true)}
            >
              {t("see_more")}
            </p>
          </div>
        </div>
        <div className="app__services-card">
          <img
            src={images.weld}
            alt="silo"
            className="app__services-card_image"
          />
          <div className="app__services-card_info">
            <h2 className="app__services-card_heading">
              {t("welding_heading")}
            </h2>
            <p
              className="app__services-card_link"
              onClick={() => setWeld(true)}
            >
              {t("see_more")}
            </p>
          </div>
        </div>
        <div className="app__services-card">
          <img
            src={images.electrotech}
            alt="silo"
            className="app__services-card_image"
          />
          <div className="app__services-card_info">
            <h2 className="app__services-card_heading">
              {t("electro_heading")}
            </h2>
            <p
              className="app__services-card_link"
              onClick={() => setElectro(true)}
            >
              {t("see_more")}
            </p>
          </div>
        </div>
        {silo && (
          <DetailsCard
            heading={t("assembly_heading")}
            paragraph={t("assembly_description")}
            image={images.silo}
            handleClose={handleCloseSilo}
          />
        )}
        {weld && (
          <DetailsCard
            heading={t("welding_heading")}
            paragraph={t("welding_description")}
            image={images.weld}
            handleClose={handleCloseWeld}
          />
        )}
        {electro && (
          <DetailsCard
            heading={t("electro_heading")}
            paragraph={t("electro_description")}
            image={images.electrotech}
            handleClose={handleCloseElectro}
          />
        )}
      </div>
    </div>
  );
};

export default Services;
