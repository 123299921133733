import React from "react";
import i18n from "./i18n";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Terms from "./Terms";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (ln) => {
    return () => {
      i18n.changeLanguage(ln);
    };
  };
  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={<Home changeLanguage={changeLanguage} t={t} />}
        />
        <Route exact path="/terms-conditions" element={<Terms t={t} />} />
      </Routes>
    </Router>
  );
}

export default App;
