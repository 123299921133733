import React, { useState } from "react";
import "./contact.css";
import { MdOutlineEmail, MdSmartphone, MdLocationPin } from "react-icons/md";
import { Link } from "react-router-dom";

const Contact = ({ t }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [consent, setConsent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      email.length === 0 ||
      name.length === 0 ||
      message.length === 0 ||
      consent === false
    ) {
      setError(
        "Proszę wypełnić obowiązkowe pola oraz zaakceptować politykę prywatności."
      );
      setSuccess("");
    } else {
      setError("");
      setSuccess(
        "Formularz został wysłany pomyślnie. Dziękujemy za wiadomość!"
      );
      setEmail("");
      setName("");
      setMessage("");
      setConsent(null);
    }

    fetch("https://formsubmit.co/ajax/kontakt@doromont.pl", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        imię: name,
        email: email,
        wiadomość: message,
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
  };

  const handleCheck = () => {
    setConsent((current) => !current);
  };

  return (
    <div className="app__contact" id="contact">
      <h1 className="app__contact-heading">{t("contact")} </h1>
      <div className="app__contact-content">
        <div className="app__contact-form_container">
          <form
            className="app__contact-form"
            onSubmit={handleSubmit}
            action="https://formsubmit.co/kontakt@doromont.pl"
            method="POST"
          >
            <input
              type="hidden"
              name="_subject"
              value="Nowa Wiadomość - Power Palety"
            ></input>
            <input type="hidden" name="_captcha" value="false"></input>
            <div className="app__contact-form_element">
              <label htmlFor="name">
                <span>*</span>
                {t("name_surname")}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="personal-input"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="app__contact-form_element">
              <label htmlFor="email">
                <span>*</span>
                {t("email")}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="personal-input"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="app__contact-form_element textarea">
              <label htmlFor="message">
                <span>*</span>
                {t("message")}
              </label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="app__contact-checkbox_container">
              <label htmlFor="consent">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  onClick={handleCheck}
                  checked={consent}
                  value={consent}
                  onChange={(e) => setConsent(e.target.checked)}
                />
                <p className="app__contact-consent">
                  {t("consent")}
                  <Link to="/terms-conditions">{t("consent_2")}</Link>
                </p>
              </label>
            </div>
            <div className="app__contact-btn_container">
              <p className="app__contact-error">{error}</p>
              <p className="app__contact-success">{success}</p>
              <button className="app__contact-btn" type="submit">
                {t("send")}
              </button>
            </div>
          </form>
        </div>
        <div className="app__contact-additional">
          <div className="app__contact-additional_info">
            <h2 className="app__contact-additional_info-heading">
              {t("contact_details")}
            </h2>
            <div className="app__contact-additional_info-container">
              <div className="app__contact-additional_info-element">
                <MdOutlineEmail />
                <a
                  href="mailto:kontakt@doromont.pl"
                  className="app__contact-additional_info-content"
                >
                  kontakt@doromont.pl
                </a>
              </div>
              <div className="app__contact-additional_info-element">
                <MdSmartphone />
                <a
                  href="tel:+48 692 761 332"
                  className="app__contact-additional_info-content"
                >
                  +48 692 761 332
                </a>
              </div>
              <div className="app__contact-additional_info-element">
                <MdLocationPin />
                <p className="app__contact-additional_info-content">
                  Wojnowo 50, 87-645 Zbójno
                </p>
              </div>
            </div>
          </div>
          <div className="app__contact-additional_map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2400.4033518778315!2d19.177645215775495!3d53.013111779910574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471cc28ec1d3e661%3A0xab2560035d18dba1!2sWojnowo%2050%2C%2087-645%20Wojnowo!5e0!3m2!1spl!2spl!4v1667214373080!5m2!1spl!2spl"
              width="90%"
              height="300px"
              loading="lazy"
              title="google-maps"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
