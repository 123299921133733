import React, { useState } from "react";
import "./navbar.css";
import images from "../constants/images";
import { RiToolsFill, RiMenu3Fill } from "react-icons/ri";
import { MdSmartphone, MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const Navbar = ({ changeLanguage, t }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="app__navbar">
      <div className="app__navbar-top">
        <div className="app__navbar-logo_container">
          <Link to="/">
            <img
              src={images.logoLightNoBg}
              alt="logo"
              className="app__navbar-logo"
            />
          </Link>
          {toggle ? (
            <RiToolsFill
              className="app__navbar-menu_icon"
              onClick={() => setToggle(false)}
            />
          ) : (
            <RiMenu3Fill
              className="app__navbar-menu_icon"
              onClick={() => setToggle(true)}
            />
          )}
        </div>
        <div className="app__navbar-top_additional">
          <div className="app__navbar-top_additional-phone">
            <MdSmartphone className="app__navbar-top_additional-phone_icon" />
            <a
              href="tel:+48 692 761 332"
              className="app__navbar-top_additional-link"
            >
              +48 692 761 332
            </a>
          </div>
          <div className="separator"></div>
          <div className="app__navbar-top_additional-email">
            <MdOutlineEmail className="app__navbar-top_additional-email_icon" />
            <a
              href="mailto:kontakt@doromont.pl"
              className="app__navbar-top_additional-link"
            >
              kontakt@doromont.pl
            </a>
          </div>
          <div className="app__navbar-top_additional-lang">
            <img
              src={images.poland}
              alt="poland-flag"
              className="app__navbar-top_additional-flag"
              onClick={changeLanguage("pl")}
            />
            <img
              src={images.german}
              alt="german-flag"
              className="app__navbar-top_additional-flag"
              onClick={changeLanguage("de")}
            />
            <img
              src={images.uk}
              alt="uk-flag"
              className="app__navbar-top_additional-flag"
              onClick={changeLanguage("en")}
            />
          </div>
        </div>
      </div>
      <div className="app__navbar-menu">
        <a
          href="#about"
          className="app__navbar-menu_link"
          onClick={() => setToggle(false)}
        >
          {t("about_company")}
        </a>

        <a href="#services" className="app__navbar-menu_link">
          {t("offer")}
        </a>

        <a href="#contact" className="app__navbar-menu_link">
          {t("contact")}
        </a>
      </div>
      {toggle && (
        <div className="app__navbar-smallscreen_menu">
          <div className="app__navbar-smallscreen_menu-links">
            <a
              href="#about"
              className="app__navbar-smallscreen_menu-link"
              onClick={() => {
                setToggle(false);
              }}
            >
              {t("about_company")}
            </a>

            <a
              href="#services"
              className="app__navbar-smallscreen_menu-link"
              onClick={() => {
                setToggle(false);
              }}
            >
              {t("offer")}
            </a>

            <a
              href="#contact"
              className="app__navbar-smallscreen_menu-link"
              onClick={() => {
                setToggle(false);
              }}
            >
              {t("contact")}
            </a>
          </div>
          <div className="app__navbar-top_smallscreen-additional_lang">
            <div className="app__navbar-top_smallscreen-additional_lang-container">
              <img
                src={images.poland}
                alt="poland-flag"
                className="app__navbar-top_smallscreen-additional_flag"
                onClick={changeLanguage("pl")}
              />
              <img
                src={images.german}
                alt="german-flag"
                className="app__navbar-top_smallscreen-additional_flag"
                onClick={changeLanguage("de")}
              />
              <img
                src={images.uk}
                alt="uk-flag"
                className="app__navbar-top_smallscreen-additional_flag"
                onClick={changeLanguage("en")}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
