import React from "react";
import "./termsNavbar.css";
import "./navbar.css";
import images from "../constants/images";
import { Link } from "react-router-dom";

const TermsNavbar = () => {
  return (
    <div className="app__terms-navbar">
      <Link to="/" className="app__terms-navbar_link">
        <img src={images.logoLightNoBg} alt="" className="app__navbar-logo" />
      </Link>
    </div>
  );
};

export default TermsNavbar;
